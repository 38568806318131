import React from "react"
import Information from "../components/Information"
import Layout from "../components/Layout"
import { educationData } from "../data/education"

const EducationPage = () => {
	return (
		<Layout>
		<main className="tone-in">
			<Information content={educationData} />
		</main>
		</Layout>
	)
}

export default EducationPage