export const educationData = [
	{
		row: 1,
		heading: "It-Högskolan",
		description: "Front-End Development",
		date: "Aug 2020 - Pågående"
	},
	{
		row: 2,
		heading: "Komvux",
		description: "Datorteknik 1a, Dator- och nätverksteknik, Gränssnittsdesign, Matematik 2c, Programmering 1 & 2, Webbutveckling 1 & 2, Webbserver-programmering 1",
		date: "Aug 2019 - Jun 2020"
	},
	{
		row: 3,
		heading: "Grönlunds Yrkesutbildningar",
		description: "Yrkesförarutbildning",
		date: "Jun 2012 - Dec 2012"
	},
	{
		row: 4,
		heading: "Körkort",
		description: "Behörigheter BE CE",
		date: null
	},
]